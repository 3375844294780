.event-list {
  display: grid;
  gap: 25px 15px;
  grid-template-columns: 1fr;

  @media only screen and (min-width: #{$breakpoint-s + 1}) {
    &.-col3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (max-width: $breakpoint-s) {
    gap: 20px 10px;
  }

  > li {
    overflow: hidden;

    .photo {
      margin-bottom: 8px;
      position: relative;

      a {
        aspect-ratio: 580 / 377;
        background-color: $gray100;
        display: block;
        position: relative;
      }

      img {
        height: 100%;
        object-fit: contain;
        vertical-align: bottom;
        width: 100%;
      }

      .event-status {
        background-color: rgba(35, 35, 35, 60%);
        color: #fff;
        display: inline-block;
        font-size: 12px;
        -webkit-font-smoothing: antialiased;
        left: 10px;
        line-height: 20px;
        padding: 0 0.5em;
        position: absolute;
        top: 10px;
        z-index: 1;

        &.event-status-ongoing {
          background-color: rgba(255, 234, 234, 85%);
          color: #a94949;
        }

        &.event-status-close {
          background-color: rgba(35, 35, 35, 60%);
          color: #fff;
        }
      }

      .like-button {
        bottom: 0;
        position: absolute;
        right: 0;
      }
    }

    .info {
      flex: 1;
      position: relative;

      &.actions {
        max-width: 80px;
        padding-left: 15px;

        a {
          margin-bottom: 5px;
        }
      }

      .event-tag {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 10px;

        li a {
          background-color: $light-gray;
          color: #6d6d6d;
          display: block;
          font-size: 12px;
          padding: 4px 8px;
        }
      }

      .name,
      .maker {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .name {
        font-size: 14px;
        margin: 0;

        a {
          color: inherit;
        }
      }

      .maker {
        font-size: 13px;
        margin: 0;

        a {
          color: $link-text-color;
        }
      }

      .period {
        color: #8e8e8e;
        font-size: 13px;
        margin: 4px 0 0;
      }

      .description {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        margin: 4px 0 0;
        overflow: hidden;
      }
    }
  }
}
