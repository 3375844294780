@import '@/scss/base/variables';
@import '../../lib/components/slider';
@import './event/event-items';
@import './listing/listing-items';

/*
    www.iichi.com/people/xxxx
    www.iichi.com/shop/xxxx
*/

/* common */

#content {
  padding-top: 0;
}

.shop__bg {
  aspect-ratio: 3 / 1;
  background-color: #f7f7f7;

  img {
    height: 100%;
    object-fit: cover;
    vertical-align: bottom;
    width: 100%;
  }
}

.shop__head {
  margin: 0 -10px;
}

.shop__head__content {
  padding: 15px 20px;
  position: relative;

  @media only screen and (max-width: $breakpoint-s) {
    padding: 15px;
  }
}

.shop__head__profile {
  display: flex;
  gap: 15px;
  margin: -35px 0 20px;

  @media only screen and (max-width: $breakpoint-s) {
    flex-direction: column;
    margin: -28px 0 15px;
  }
}

.shop__head__icon {
  align-items: center;
  display: flex;
  height: 100px;

  a {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 20%);
    display: block;
    height: 100px;
    max-height: calc(100vw - 270px);
    max-width: calc(100vw - 270px);
    overflow: hidden;
    width: 100px;
  }

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

.shop__head__text {
  @media only screen and (min-width: #{$breakpoint-s + 1}) {
    padding-top: 30px;
    width: 500px;
  }
}

.shop__head__name {
  font-size: 20px;
  font-weight: bold;

  > a {
    color: inherit;
  }
}

.shop__head__pref {
  font-size: 12px;
  margin-top: 4px;

  span {
    margin-left: 10px;
  }
}

.notifications {
  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  li {
    display: flex;
    font-size: 14px;
    gap: 8px;

    &::before {
      align-self: flex-start;
      border: 1px solid $gray900;
      border-radius: 2px;
      color: $gray900;
      flex-shrink: 0;
      font-size: 11px;
      line-height: 1;
      padding: 4px;
    }

    a {
      color: inherit;
    }
  }
}

.notifications__event::before {
  content: 'イベント';
}

.notifications__item::before {
  content: '新着作品';
}

.notifications__information::before {
  content: 'お知らせ';
}

.shop__head__actions {
  align-items: center;
  display: flex;
  gap: 12px 20px;
  position: absolute;
  right: 20px;
  top: 15px;

  @media only screen and (max-width: $breakpoint-s) {
    align-items: flex-end;
    flex-direction: column-reverse;
    right: 15px;
  }

  .flex {
    align-items: center;
    display: flex;
    gap: 14px;

    @media only screen and (max-width: $breakpoint-s) {
      flex-direction: row-reverse;
      gap: 12px;
    }
  }
}

.shop-info-action {
  grid-area: shopInfoAction;
}

.item-action {
  grid-area: itemAction;
  position: relative;

  .follow-recommend {
    bottom: -10px;
    left: 110px;
    pointer-events: none;
    position: absolute;
  }
}

.item-action-inquiry {
  display: block;
  grid-area: itemActionInquiry;

  > div {
    text-align: center;
  }
}

.free-shipping {
  color: $dark-red;
  font-size: 14px;
  margin-bottom: 12px;
}

.ui-tab {
  border-bottom: 1px solid $border-color;
  margin: 5px -10px 20px;
  padding-bottom: 0;
  position: relative;

  ul {
    display: flex;
    gap: 8px;
    margin-bottom: -1px;
    margin-right: 0;
    overflow: scroll hidden;
    padding: 0 10px;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      border-radius: 0;
      font-size: 15px;
      height: 39px;
      padding: 0;
      position: relative;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        background-color: #fff;
        background-image: none;
        border-bottom-color: #fff;
        font-weight: bold;

        &::after {
          background-color: $black;
          border-radius: 1px;
          bottom: 0;
          content: '';
          height: 2px;
          left: 0;
          position: absolute;
          width: 100%;
        }

        > a,
        > span {
          color: #121212;
        }
      }

      > a,
      > span {
        color: $black;
        display: block;
        padding: 9px 2px 7px;

        > span {
          color: inherit;
          display: inline;
          font-size: 12px;
          padding: 0 0 0 5px;
        }
      }
    }
  }

  + .header-category.button-gray-gradient {
    margin-top: 20px;
  }
}

#main {
  position: relative;

  .section {
    margin-bottom: 30px;
  }

  .flex {
    display: flex;
  }

  h1 {
    font-size: 16px;
    margin-bottom: 15px;
    padding: 0;
  }

  .prof-edit {
    margin-left: 15px;
    position: relative;
    top: 3px;
  }

  h2 {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .more {
    margin: 1px 0 0 15px;
    padding: 1px 0 0;

    &::before {
      content: '>';
      display: inline-block;
      margin-right: 4px;
      position: relative;
      top: -1px;
    }

    a {
      color: $black;
    }
  }
}

/* プロフィール */

.about-me {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
  position: relative;

  @media only screen and (min-width: #{$breakpoint-s + 1}) {
    background-color: #f7f7f7;
    flex-direction: row;
    min-height: 100px;
    padding: 20px;
  }

  p {
    line-height: 1.7;
  }

  .text {
    flex: 1;
  }

  .about-me-slide {
    @media only screen and (min-width: #{$breakpoint-s + 1}) {
      width: 340px;
    }
  }
}

.storefronts {
  p {
    margin-bottom: 15px;
  }

  .storefront-content {
    display: flex;
    gap: 10px 20px;

    @media only screen and (max-width: $breakpoint-s) {
      flex-direction: column;
    }

    > div:first-child {
      flex: 1;
    }

    > div:last-child {
      width: 560px;

      @media only screen and (max-width: $breakpoint-s) {
        width: auto;
      }
    }
  }

  .map {
    margin-bottom: 8px;

    img {
      height: auto;
      width: 100%;
    }
  }

  .more-on-google {
    text-align: right;

    a::before {
      content: '>';
      margin-right: 5px;
    }
  }
}

.feedbacks {
  li {
    display: flex;
    gap: 20px;
    margin-bottom: 12px;
  }

  .message {
    background-color: #fff;
    border: 1px solid #e6e2df;
    border-radius: 3px;
    display: flex;
    flex: 1;
    gap: 15px;
    padding: 15px;
    position: relative;

    @media only screen and (max-width: $breakpoint-s) {
      gap: 10px;
      padding: 10px;
    }

    &::before {
      border-color: transparent transparent transparent #e6e2df;
      border-style: solid;
      border-width: 8px 0 8px 14px;
      content: '';
      height: 0;
      position: absolute;
      right: -14px;
      top: 18px;
      width: 0;
    }

    &::after {
      border-color: transparent transparent transparent #fff;
      border-style: solid;
      border-width: 8px 0 8px 14px;
      content: '';
      height: 0;
      position: absolute;
      right: -12px;
      top: 18px;
      width: 0;
    }

    .photo {
      width: 75px;
    }

    .info {
      flex: 1;

      .info-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        @media only screen and (max-width: $breakpoint-s) {
          flex-direction: column;
        }

        .prd-name {
          color: #787878;
        }
      }
    }
  }

  .buyer {
    width: 50px;

    .icon {
      aspect-ratio: 1 / 1;

      img {
        height: 100%;
        object-fit: cover;
        vertical-align: bottom;
        width: 100%;
      }
    }

    .name a {
      color: #000;
    }
  }
}

.other-links {
  font-size: 14px;
  margin-top: 12px;

  a {
    color: inherit;

    span {
      display: inline-block;
      margin-right: 5px;
      text-align: center;
      width: 1em;
    }
  }

  > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    ul {
      display: none;
    }

    div ul {
      display: grid;
      gap: 10px;
      text-align: left;

      @media only screen and (min-width: #{$breakpoint-s + 1}) {
        gap: 20px;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

/* 作品一覧 */

.items-head {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 15px;

  @media only screen and (max-width: $breakpoint-s) {
    flex-direction: column;
  }
}

.button-gray-gradient {
  background-color: #fff;
  color: #121212;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.6;
  padding: 5px 35px 5px 0;
  position: relative;

  @media only screen and (max-width: $breakpoint-s) {
    border: 1px solid $border-color;
    padding: 5px 38px 5px 10px;
  }

  &::after {
    color: #9a9585;
    content: $icon-arrow-bottom;
    font-family: $font-icon;
    font-size: 28px;
    line-height: 1;
    position: absolute;
    right: 5px;
    top: 2px;
  }

  &.active::after {
    transform: rotate(180deg);
  }
}

details[open] .button-gray-gradient::after {
  transform: rotate(180deg);
}

.dropdown {
  border: 1px solid $border-color;
  border-width: 0 1px 1px;
  max-height: 265px;
  overflow-y: auto;

  @media only screen and (min-width: #{$breakpoint-s + 1}) {
    background-color: $white;
    border-width: 1px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 10%);
    max-height: 440px;
    position: absolute;
    z-index: 1;
  }

  ul {
    padding: 6px 0;

    li {
      a {
        color: inherit;
        display: block;
        font-size: 14px;
        padding: 4px 10px;
        user-select: none;
      }

      &.active {
        font-weight: bold;
      }
    }

    &.dropdown-collections {
      border: 1px solid $border-color;
      border-width: 1px 0;

      @media only screen and (min-width: #{$breakpoint-s + 1}) {
        border-width: 1px 0 0;
      }
    }
  }
}

#change-view {
  font-size: 14px;

  > form {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  h2 {
    color: #000;
    font-weight: bold;
    margin-bottom: 0;
  }

  .section {
    line-height: 1.8;
    margin-left: 12px;

    a {
      color: #51452e;
      margin: 0 8px;
      text-decoration: underline;
    }
  }

  select {
    border-radius: 3px;
    height: 23px;
    padding: 0 0 0 3px;
    position: relative;
  }

  input {
    font-size: 14px;
    margin: 0 2px 0 6px;
  }

  label {
    cursor: pointer;
  }
}

.items-sort {
  height: 21px;
  margin-bottom: 10px;

  #listing-sort {
    text-align: right;
  }
}

.listing .photo img {
  height: 100%;
  vertical-align: bottom;
  width: 100%;
}

/* お知らせ */

.update-date {
  color: $gray500;
  margin-left: 15px;
  position: relative;
  top: 3px;
}

/* 感想コメント */

.feedback-body {
  .box {
    border-top: 1px solid #edeae3;
    display: flex;
    gap: 10px;
    padding: 15px 0;

    @media only screen and (max-width: $breakpoint-s) {
      flex-direction: column;
    }

    .item {
      width: 165px;

      @media only screen and (max-width: $breakpoint-s) {
        margin-bottom: 10px;
        width: auto;
      }

      img {
        background-color: #fff;
        border: 1px solid #edeae3;
        padding: 4px;
      }

      .name {
        color: #666;
      }
    }

    .comment {
      flex: 1;
    }
  }

  .area {
    background-color: #fff;
    border: 1px solid #e5ddc7;
    border-radius: 3px;
    line-height: 1.7;
    padding: 15px;
    position: relative;

    .area-head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .star img {
        position: relative;
        top: 2px;
      }

      .date {
        color: #7a7a7a;
        font-weight: bold;
      }
    }

    .area-body {
      display: flex;
      gap: 15px;

      .el-dialog__body {
        text-align: center;

        img {
          box-sizing: border-box;
          max-width: 100%;
        }
      }
    }
  }
}

/* 作り手 */

.maker-info {
  background-color: #fff;
  border: 1px solid #e6e2df;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  padding: 20px;

  @media only screen and (max-width: $breakpoint-s) {
    padding: 15px;
  }

  .maker-description {
    flex: 1;
    line-height: 1.7;
  }

  .maker-photo {
    @media only screen and (max-width: $breakpoint-s) {
      display: none;
    }
  }
}
